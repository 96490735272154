/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { equals as t } from "../../core/arrayUtils.js";
import { Axis as n } from "./Axis.js";
import { getInfo as r } from "./spatialReferenceUtils.js";
function e(t) {
  if (!t) return null;
  if (Array.isArray(t)) return t;
  const n = t.hasZ,
    r = t.hasM;
  if ("point" === t.type) return r && n ? [t.x, t.y, t.z, t.m] : n ? [t.x, t.y, t.z] : r ? [t.x, t.y, t.m] : [t.x, t.y];
  if ("polygon" === t.type) return t.rings.slice();
  if ("polyline" === t.type) return t.paths.slice();
  if ("multipoint" === t.type) return t.points.slice();
  if ("extent" === t.type) {
    const n = t.clone().normalize();
    if (!n) return null;
    let r = !1,
      e = !1;
    return n.forEach(t => {
      t.hasZ && (r = !0), t.hasM && (e = !0);
    }), n.map(t => {
      const n = [[t.xmin, t.ymin], [t.xmin, t.ymax], [t.xmax, t.ymax], [t.xmax, t.ymin], [t.xmin, t.ymin]];
      if (r && t.hasZ) {
        const r = .5 * (t.zmax - t.zmin);
        for (let t = 0; t < n.length; t++) n[t].push(r);
      }
      if (e && t.hasM) {
        const r = .5 * (t.mmax - t.mmin);
        for (let t = 0; t < n.length; t++) n[t].push(r);
      }
      return n;
    });
  }
  return null;
}
function i(t, n) {
  const r = n[0] - t[0],
    e = n[1] - t[1];
  if (t.length > 2 && n.length > 2) {
    const i = t[2] - n[2];
    return Math.sqrt(r * r + e * e + i * i);
  }
  return Math.sqrt(r * r + e * e);
}
function o(t, n, r) {
  const e = t[0] + r * (n[0] - t[0]),
    i = t[1] + r * (n[1] - t[1]);
  return t.length > 2 && n.length > 2 ? [e, i, t[2] + r * (n[2] - t[2])] : [e, i];
}
function s(t, n, r, e) {
  return f(t, n, r[e], r[e + 1]);
}
function f(t, n, r, e) {
  const [i, o] = n,
    [s, f] = r,
    [u, l] = e,
    c = u - s,
    h = l - f,
    p = c * c + h * h,
    a = (i - s) * c + (o - f) * h,
    y = Math.min(1, Math.max(0, a / p));
  return t[0] = s + c * y, t[1] = f + h * y, t;
}
function u(t, n, r) {
  let e,
    i,
    o,
    s,
    f = !1,
    u = 1 / 0;
  for (r.reset(); r.nextPath();) if (r.nextPoint()) for (e = r.x, i = r.y; r.nextPoint();) o = r.x, s = r.y, i > n != s > n && t < (o - e) * (n - i) / (s - i) + e && (f = !f), u = Math.min(u, l(t, n, e, i, o, s)), e = o, i = s;
  return 0 === u ? 0 : (f ? 1 : -1) * Math.sqrt(u);
}
function l(t, n, r, e, i, o) {
  let s = r,
    f = e,
    u = i - s,
    l = o - f;
  if (0 !== u || 0 !== l) {
    const r = ((t - s) * u + (n - f) * l) / (u * u + l * l);
    r > 1 ? (s = i, f = o) : r > 0 && (s += u * r, f += l * r);
  }
  return u = t - s, l = n - f, u * u + l * l;
}
function c(t, n) {
  return o(t, n, .5);
}
function h(t) {
  const n = t.length;
  let r = 0;
  for (let e = 0; e < n - 1; ++e) r += i(t[e], t[e + 1]);
  return r;
}
function p(t, n) {
  if (n <= 0) return t[0];
  const r = t.length;
  let e = 0;
  for (let s = 0; s < r - 1; ++s) {
    const r = i(t[s], t[s + 1]);
    if (n - e < r) {
      const i = (n - e) / r;
      return o(t[s], t[s + 1], i);
    }
    e += r;
  }
  return t[r - 1];
}
function a(t, r = n.X, e = n.Y) {
  let i = 0;
  const o = t.length;
  let s = t[0];
  for (let n = 1; n < o; n++) {
    const o = t[n];
    i += (o[r] - s[r]) * (o[e] + s[e]), s = o;
  }
  if (!y(t)) {
    const n = t[0];
    i += (n[r] - s[r]) * (n[e] + s[e]);
  }
  return i >= 0;
}
function y(n) {
  const r = n.length;
  return r < 3 || t(n[0], n[r - 1]);
}
function m(t) {
  "rings" in t && (g(t), x(t));
}
function g(t) {
  if (!("rings" in t)) return !1;
  let n = !1;
  for (const r of t.rings) y(r) || (r.push(r[0].slice()), n = !0);
  return n;
}
function x(t) {
  if (!("rings" in t)) return !1;
  if (0 === t.rings.length || a(t.rings[0])) return !1;
  for (const n of t.rings) n.reverse();
  return !0;
}
function M(t) {
  if ("polygon" !== t.type && "polyline" !== t.type) return t;
  return z("polygon" === t.type ? t.rings : t.paths, t.spatialReference), t;
}
function z(t, n) {
  const e = r(n);
  if (!e) return;
  const i = e.valid[0],
    o = e.valid[1],
    s = o - i;
  for (const r of t) {
    let t = 1 / 0,
      n = -1 / 0;
    for (const s of r) {
      const r = v(s[0], i, o);
      t = Math.min(t, r), n = Math.max(n, r), s[0] = r;
    }
    const e = n - t;
    s - e < e && r.forEach(t => {
      t[0] < 0 && (t[0] += s);
    });
  }
}
function v(t, n, r) {
  const e = r - n;
  return t < n ? r - (n - t) % e : t > r ? n + (t - n) % e : t;
}
function j(t, n) {
  if (t === n) return !0;
  if (t.type !== n.type) return !1;
  if ("point" === t.type || "mesh" === t.type || "extent" === t.type) return !0;
  if ("multipoint" === t.type) return t.points.length === n.points.length;
  const [r, e] = "polyline" === t.type ? [t.paths, n.paths] : [t.rings, n.rings];
  return r.length === e.length && r.every((t, n) => t.length === e[n].length);
}
export { g as closeRings, m as closeRingsAndFixWinding, u as distanceFromPointToPolygon, l as distanceToSegmentSquared, x as fixWinding, e as geometryToCoordinates, i as getLength, c as getMidpoint, h as getPathLength, p as getPointOnPath, j as hasCompatibleTopology, a as isClockwise, y as isClosed, s as projectPointOnLine, f as projectPointOnLineSeg, M as unnormalizeGeometryOnDatelineCrossing, z as unnormalizeVerticesOnDatelineCrossing, v as unnormalizedCoordinate };